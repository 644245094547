'use client'
import React from 'react'
import { useAppContext } from './appContextProvider'
import { usePathname } from 'next/navigation'
import { navBuilder } from '~/lib/nav/nav-builder'

export default function NavProvider({ children }: { children: React.ReactNode }) {
  const { context, setContext } = useAppContext()
  const pathname = usePathname()

  React.useEffect(() => {
    setContext({
      ...context,
      nav: navBuilder(pathname)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return <>{children}</>
}
